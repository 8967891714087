define("discourse/plugins/discourse-chat-integration/admin/routes/admin-plugins-chat-integration-provider", ["exports", "@ember/object", "discourse/models/group", "discourse/routes/discourse"], function (_exports, _object, _group, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let AdminPluginsChatIntegrationProvider = _exports.default = (_class = class AdminPluginsChatIntegrationProvider extends _discourse.default {
    async model(params) {
      const [channels, provider, groups] = await Promise.all([this.store.findAll("channel", {
        provider: params.provider
      }), this.modelFor("admin-plugins-chat-integration").findBy("id", params.provider), _group.default.findAll()]);
      channels.forEach(channel => {
        channel.set("rules", channel.rules.map(rule => {
          rule = this.store.createRecord("rule", rule);
          rule.set("channel", channel);
          return rule;
        }));
      });
      return {
        channels,
        provider,
        groups
      };
    }
    serialize(model) {
      return {
        provider: model.provider.id
      };
    }
    refreshProvider() {
      this.refresh();
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "refreshProvider", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshProvider"), _class.prototype)), _class);
});